<template>
  <div class="">
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <!-- 建立供應商身份start -->
      <div class=" flex justify-center items-center my-5">
        <div class="pl-5">
          <div class="mb-10 text-base text-gray-600">
            本平台提供您建立公司資料，成為平台供應商。<br />
            成為平台供應商將享有以下服務：
            <ul class=" list-decimal pl-5 mt-5 mb-10">
              <li>建立公司完整資料，與平台會員交流。</li>
              <li>上架公司產品資訊。</li>
              <li>於軟協數位展覽入選展出公司產品與公司介紹。開拓公司商機。</li>
            </ul>
            申請成為平台供應商，請提供以下資料進行審核：
            <ul class=" list-decimal pl-5 mt-5 mb-10">
              <li>政府核准設立之公司統編。</li>
              <li>公司登記名稱。</li>
              <li>公司登記地址。</li>
              <li>公司核准設立日期。</li>
              <li>公司資本額。</li>
            </ul>
          </div>
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#company-modal"
            class="button button--lg  text-white bg-theme-1 shadow-md mr-2  inline-flex items-center a-disabled"
          >
            <ArrowUpIcon class="w-4 h-4 mr-2" />
            建立供應商身份
          </a>
        </div>
      </div>

      <!-- 建立供應商身份end -->
    </div>
    <div class="intro-y box p-10 mt-5">
      <div class="flex justify-center items-center my-5">
        <div class="">
          <VForm
            :validation-schema="validationSchema"
            @submit="submit"
            :initial-values="formData"
          >
            <div
              class="mb-10 text-base text-gray-600 flex flex-row items-center"
            >
              <div>
                <CfInputBox
                  label=""
                  name="code"
                  placeholder="輸入邀請碼"
                  type="text"
                  :isRequired="true"
                  :modelValue="code"
                  @update:modelValue="
                    val => {
                      code = val;
                    }
                  "
                ></CfInputBox>
              </div>
              <div class="pl-2">
                <button
                  type="submit"
                  class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
                >
                  <CheckIcon class="mr-2 w-4 h-4" />
                  驗證邀請碼
                </button>
              </div>
            </div>
            <div class="text-base text-red-500 justify-center">
              {{ errorMessage }}
            </div>
          </VForm>
        </div>
      </div>
    </div>

    <!-- END: HTML Table Data -->

    <div id="success-modal" class="modal">
      <div class="modal__content">
        <div class="p-5 text-center">
          <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
          <div class="text-3xl mt-5">供應商身份轉換完成</div>
          <div class="text-gray-600 mt-2">
            邀請碼輸入成功
          </div>
        </div>
        <div class="px-5 pb-8 text-center">
          <button
            type="button"
            class="button w-24 bg-theme-1 text-white"
            @click="gotoDashboard"
          >
            確定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const formData = reactive({ code: "" });
    const model = CloudFun.current?.model;
    const validationSchema = Yup.object().shape({
      code: Yup.string().required("請輸入邀請碼")
    });
    const successModalPreview = ref(false);
    const errorMessage = ref("");
    const info = ref({} as any);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
    });

    return {
      formData,
      validationSchema,
      successModalPreview,
      errorMessage,
      info
    };
  },
  methods: {
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    gotoDashboard() {
      cash("#success-modal").modal("hide");
      localStorage.setItem("upgrade", this.info.account);
      sessionStorage.clear();
      this.$router.replace("/login");

      // CloudFun.send("reloadAuth", "");
      // this.$router.replace("/dashboard");
    }
  }
});
</script>
<style scoped>
.a-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>
