
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const formData = reactive({ code: "" });
    const model = CloudFun.current?.model;
    const validationSchema = Yup.object().shape({
      code: Yup.string().required("請輸入邀請碼")
    });
    const successModalPreview = ref(false);
    const errorMessage = ref("");
    const info = ref({} as any);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
    });

    return {
      formData,
      validationSchema,
      successModalPreview,
      errorMessage,
      info
    };
  },
  methods: {
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    gotoDashboard() {
      cash("#success-modal").modal("hide");
      localStorage.setItem("upgrade", this.info.account);
      sessionStorage.clear();
      this.$router.replace("/login");

      // CloudFun.send("reloadAuth", "");
      // this.$router.replace("/dashboard");
    }
  }
});
